import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/router/index.js'
import store from './store/store'
import './registerServiceWorker'

Vue.prototype.$event = new Vue()
Vue.config.productionTip = false

Vue.mixin({
  methods: {
    toDTLocal: dt => {
      const d = new Date(dt + 'Z')
      return d.toLocaleString('en-US', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      })
    },
    formatDT: dt => {
      const d = new Date(dt)
      return d.toLocaleString('en-US', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit'
      })
    },
    formatDate: dt => {
      const d = new Date(dt)
      return d.toLocaleString('en-US', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      })
    },
    sortDtDesc(a, b) {
      if (a.datetime_utc < b.datetime_utc) {
        return 1
      }
      if (a.datetime_utc > b.datetime_utc) {
        return -1
      }
      return 0
    },
    formatEdits: (path, lhs, rhs) => {
      path += ''
      path = path.split('_')
      for (var i = 0; i < path.length; i++) {
        path[i] =
          path[i].slice(0, 1).toUpperCase() + path[i].slice(1, path[i].lenght)
      }
      return path.join(' ') + ' from "' + lhs + '" to "' + rhs + '". '
    },
    safeToFixed: (amount, val) => {
      if (amount == null) {
        return null
      } else {
        return amount.toFixed(val)
      }
    },
    localToday: () => {
      const d = new Date()
      const t = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate())
      return new Date(t).toISOString().split('T')[0]
    },
    usd(val) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(val)
    }
  }
})

axios
  .get('/config.json')
  .then(function (response) {
    store.commit('setConfig', response.data)
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(error => void error)
