<template>
  <v-container>
    <template v-if="$store.getters.user">
      <v-row justify="space-around">
        <v-col v-for="card in $store.state.cards" :key="card.code">
          <v-card
            @click="cardDetails(card.code)"
            width="300"
            color="teal lighten-1"
            dark
          >
            <v-responsive :aspect-ratio="43 / 27">
              <v-card-title>
                {{ usd(card.balance) }}
                <v-spacer />
                <v-btn fab x-small text @click.stop="openDialog(card)"
                  ><v-icon>mdi-cog</v-icon>
                </v-btn>
              </v-card-title>
              <site-logo color="black" class="mx-10" :opacity="0.6" />
              <v-container class="mt-3 ml-2">
                <v-row align="end">
                  <v-col cols="10">
                    {{ card.codename }}
                  </v-col>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template #activator="{ on }">
                        <v-icon
                          v-show="card.user_id != $store.getters.user.user_id"
                          small
                          v-on="on"
                          >mdi-share-variant
                        </v-icon>
                      </template>
                      <span>Another user is sharing this card with you.</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-container>
            </v-responsive>
          </v-card>
        </v-col>
        <v-col>
          <v-card width="300" color="teal lighten-4">
            <v-responsive :aspect-ratio="43 / 27">
              <v-container class="text-center fill-height">
                <v-row align="center">
                  <v-col v-show="!add" @click="add = true">
                    <v-col>Add a card</v-col>
                    <v-icon x-large>mdi-plus-circle-outline</v-icon>
                    <v-col v-if="!$store.state.cards.length">
                      to get started
                    </v-col>
                  </v-col>
                  <v-col v-show="add">
                    <v-row justify="center">
                      <v-col cols="12" class="py-0">How many?</v-col>
                      <v-col cols="4" class="py-0">
                        <v-select
                          :items="numberOfCards"
                          v-model="number_of_cards"
                          :rules="[rules.required]"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="space-around">
                      <v-btn tile @click="add = false">Cancel</v-btn>
                      <v-btn tile color="primary" @click="checkout">
                        Checkout
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-container v-else class="text-center">
      Scan an IOU Kid QR Code<br />OR enter a card code:
      <v-row justify="center">
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="code"
            @keyup.13="cardDetails(code)"
            append-icon="mdi-magnify"
            @click:append="cardDetails(code)"
          />
        </v-col>
      </v-row>
      <v-row v-show="recent.length" justify="center">
        <v-col cols="12" sm="4">
          Recenty Viewed Cards:
          <div v-for="card in recent" :key="card.index">
            <router-link :to="`card/${card}`">{{ card }}</router-link>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="text-center text-caption pt-10">
          <b>Parents:</b> Learn about IOU Kid and
          <a :href="$store.state.appConfig.landingUrl">sign up</a> for an
          account,<br />or <a @click="$emit('sign-in')">sign in</a> if you
          already have one.
        </v-col>
      </v-row>
    </v-container>
    <!-- edit card dialog -->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-form
        ref="card"
        v-model="valid"
        lazy-validation
        @submit.prevent="putCard"
      >
        <v-card>
          <v-card-title>
            {{ card.code }}
            <v-spacer />
            <v-btn @click="showDialog = false" icon small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="9">
                <v-text-field label="Name" v-model="card.name" />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="9">
                <v-text-field
                  label="Borrow Limit"
                  type="number"
                  v-model.number="card.borrow_limit"
                  :rules="[rules.decimal]"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="12" md="9">
                <v-text-field
                  label="Interest % (APR)"
                  type="number"
                  v-model.number="card.apr"
                  :rules="[rules.digit]"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn
              v-if="card.user_id && card.user_id == $store.getters.user.user_id"
              @click="cancelCard"
              tile
              color="error"
              :disabled="saving || !!card.cancel_utc || card.override_billing"
              >Cancel Card
            </v-btn>
            <v-spacer />
            <v-btn
              @click="showDialog = false"
              tile
              color="secondary"
              :disabled="saving"
              >Cancel
            </v-btn>
            <v-btn
              tile
              color="primary"
              :loading="saving"
              type="submit"
              :disabled="!valid"
              >Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import IouKidApi from "@/services/IouKidApi.js";
import rules from "@/utils/rules.js";

export default {
  name: "HomeView",
  components: {
    SiteLogo: () => import("@/components/SiteLogo.vue"),
  },
  data: () => ({
    code: null,
    showDialog: false,
    card: {},
    valid: true,
    saving: false,
    add: false,
    number_of_cards: 1,
    recent: [],
    rules,
  }),
  computed: {
    numberOfCards() {
      return [...Array(11).keys()].slice(1);
    },
  },
  created() {
    this.$emit("get-cards");
    if (localStorage.recent) this.recent = JSON.parse(localStorage.recent);
  },
  methods: {
    cardDetails(code) {
      this.$router.push(`card/${code || this.code}`);
    },
    openDialog(card) {
      this.card = JSON.parse(JSON.stringify(card));
      this.showDialog = true;
    },
    async putCard() {
      await IouKidApi.api
        .put(`card/${this.card.code}`, this.card)
        .then(() => {
          this.$event.$emit("show-snackbar", "Changes Saved", "success");
          this.showDialog = false;
          this.$emit("get-cards");
        })
        .catch((error) => {
          this.$event.$emit("show-snackbar", error.message, "error");
        });
    },
    checkout() {
      this.$store.commit("setState", {
        prop: "numberOfCards",
        value: this.number_of_cards,
      });
      this.$router.push("/checkout");
    },
    async cancelCard() {
      if (
        confirm(`Are you sure you want to cancel this card?
You will have access to it until its subscription expires on ${this.formatDate(
          this.card.expiration_date
        )}.`)
      )
        await IouKidApi.api
          .put(`card/${this.card.code}/cancel`)
          .then(async () => {
            this.$event.$emit("show-snackbar", "Card Canceled", "success");
            this.showDialog = false;
            this.$emit("get-cards");
          })
          .catch((error) => {
            this.$event.$emit("show-snackbar", error.message, "error");
          });
    },
  },
};
</script>
