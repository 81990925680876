import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store.js'
import Home from '@/views/Home.vue'
import Card from '@/views/Card.vue'
import Allowance from '@/views/Allowance.vue'
import Checkout from '@/views/Checkout.vue'
import Sharing from '@/views/Sharing.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    meta: { authorize: false }
  },
  {
    path: '/checkout',
    component: Checkout,
    name: 'Checkout',
    meta: { authorize: true, title: ' - Checkout' }
  },
  {
    path: '/card/:code',
    component: Card,
    name: 'Card',
    props: true,
    meta: { authorize: false, title: ' - Card Details' }
  },
  {
    path: '/allowance',
    component: Allowance,
    name: 'Allowance',
    meta: { authorize: true, title: ' - Allowance' }
  },
  {
    path: '/sharing',
    component: Sharing,
    name: 'Sharing',
    meta: { authorize: true, title: ' - Sharing' }
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.authorize) {
    if (!store.getters.user) {
      return next({ path: '/' })
    }
  }
  next()
})

router.afterEach((to) => {
  let title = store.state.appTitle
  if (to.meta.title) {
    title += to.meta.title
  }
  document.title = title
  // check for service worker updates
  if (!store.state.updateExists && store.state.registration) {
    store.state.registration.update()
  }
})

export default router
