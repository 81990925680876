<template>
  <v-container>
    <v-card>
      <v-card-title>
        Allowances
        <v-spacer />
        <v-btn @click="openDialog()" icon>
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-data-table
        :headers="allowanceHeaders"
        :items="allowances"
        hide-default-footer
        disable-sort
        disable-pagination
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.amount`]="{ item }">
          {{ usd(item.amount) }}
        </template>
        <template v-slot:[`item.schedule`]="{ item }">
          {{ frequencies.find((x) => x.value == item.frequency).text }}
          {{
            item.frequency != "D"
              ? ` on ${
                  item.frequency == "W"
                    ? daysOfWeek.find((x) => x.value == item.parameter).text
                    : ` the ${ordinal_suffix(item.parameter)}`
                }`
              : ""
          }}
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-btn fab x-small text color="primary" @click.stop="openDialog(item)"
            ><v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- allowance dialog -->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-form
        ref="allowance"
        v-model="valid"
        lazy-validation
        @submit.prevent="upsertAllowance"
      >
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
            <v-spacer />
            <v-btn
              v-if="allowance.allowance_id"
              @click="deleteAllowance"
              icon
              small
            >
              <v-icon color="error">mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-container>
            <v-row justify="center">
              <v-col cols="9">
                <v-select
                  :items="$store.state.cards"
                  item-text="codename"
                  item-value="code"
                  label="Card *"
                  v-model="allowance.code"
                  :rules="[rules.required]"
                  :disabled="!!allowance.allowance_id"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="9">
                <v-text-field
                  label="Amount *"
                  type="number"
                  v-model.number="allowance.amount"
                  :rules="[rules.required, rules.decimal]"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="9">
                <v-select
                  :items="frequencies"
                  v-model="allowance.frequency"
                  label="Frequency *"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="9">
                <v-select
                  v-if="allowance.frequency == 'W'"
                  :items="daysOfWeek"
                  v-model="allowance.parameter"
                  label="Day of Week *"
                  :rules="[rules.required]"
                />
                <v-select
                  v-if="allowance.frequency == 'M'"
                  :items="daysOfMonth"
                  v-model="allowance.parameter"
                  label="Day of Month *"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="showDialog = false"
              tile
              color="secondary"
              :disabled="saving || deleting"
              >Cancel
            </v-btn>
            <v-btn
              tile
              color="primary"
              :loading="saving"
              :disabled="!valid || deleting"
              type="submit"
              >Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import IouKidApi from "@/services/IouKidApi.js";
import rules from "@/utils/rules.js";

export default {
  name: "AllowanceView",
  data: () => ({
    allowanceHeaders: [
      { text: "Card", value: "codename" },
      { text: "Amount", value: "amount" },
      { text: "Schedule", value: "schedule" },
      { text: "Edit", value: "edit" },
    ],
    frequencies: [
      { text: "Daily", value: "D" },
      { text: "Weekly", value: "W" },
      { text: "Monthly", value: "M" },
    ],
    daysOfWeek: [
      { text: "Sunday", value: 1 },
      { text: "Monday", value: 2 },
      { text: "Tuesday", value: 3 },
      { text: "Wednesday", value: 4 },
      { text: "Thursday", value: 5 },
      { text: "Friday", value: 6 },
      { text: "Saturday", value: 7 },
    ],
    allowance: {},
    showDialog: false,
    valid: true,
    saving: false,
    deleting: false,
    rules,
  }),
  computed: {
    allowances() {
      let allowances = [];
      if (this.$store.state.cards) {
        allowances = this.$store.state.cards
          .map((c) =>
            c.allowances.map((a) => ({
              ...a,
              code: c.code,
              codename: c.codename,
            }))
          )
          .flat();
      }
      return allowances;
    },
    daysOfMonth() {
      return [...Array(32).keys()].slice(1);
    },
    dialogTitle() {
      return `${this.allowance.allowance_id ? "Edit" : "New"} Allowance`;
    },
  },
  created() {
    this.$emit("get-cards");
  },
  methods: {
    openDialog(allowance) {
      if (allowance) {
        this.allowance = JSON.parse(JSON.stringify(allowance));
      } else {
        this.allowance = {};
        requestAnimationFrame(() => {
          this.$refs.allowance.reset();
        });
      }
      this.showDialog = true;
    },
    async upsertAllowance() {
      if (this.$refs.allowance.validate()) {
        this.saving = true;
        if (this.allowance.allowance_id) {
          await IouKidApi.api
            .put(`allowance/${this.allowance.allowance_id}`, this.allowance)
            .then(() => {
              this.showDialog = false;
              this.$emit("get-cards");
            })
            .catch((error) => {
              this.$event.$emit("show-snackbar", error.message, "error");
            });
        } else {
          await IouKidApi.api
            .post("allowance", this.allowance)
            .then(() => {
              this.showDialog = false;
              this.$emit("get-cards");
            })
            .catch((error) => {
              this.$event.$emit("show-snackbar", error.message, "error");
            });
        }
        this.saving = false;
      }
    },
    async deleteAllowance() {
      this.deleting = true;
      if (confirm("Are you sure you want to delete this allowance?"))
        await IouKidApi.api
          .delete(`allowance/${this.allowance.allowance_id}`)
          .then(() => {
            this.showDialog = false;
            this.$emit("get-cards");
          })
          .catch((error) => {
            this.$event.$emit("show-snackbar", error.message, "error");
          });
      this.deleting = false;
    },
    ordinal_suffix(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },
  },
};
</script>
