const rules = {
  required: value => !!value || value == 0 || 'Required.',
  digit: value =>
    value == null ||
    value.toString().length === 0 ||
    /^\d+$/.test(value) ||
    'Invalid Number',
  decimal: value =>
    value == null ||
    value.toString().length === 0 ||
    /^[0-9]*\.?[0-9]+$/.test(value) ||
    'Invalid Number',
  currency: value =>
    value == null ||
    value.length === 0 ||
    /^[0-9]+(\.[0-9]{1,2})?$/.test(value) ||
    'Invalid Currency',
  phone: value =>
    value == null ||
    value.toString().length === 0 ||
    /^(\()?\d{3}(\))?(\s)?\d{3}(-)\d{4}$/.test(value) ||
    'Invalid Phone',
  email: value =>
    value == null ||
    value.length === 0 ||
    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
    'Invalid E-mail'
}

export default rules
