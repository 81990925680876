import axios from 'axios'
import router from '@/router'
import store from '@/store/store'
import jsCookie from 'js-cookie'

const IouKidApi = {
  get api() {
    let api = axios.create({
      baseURL: store.state.appConfig.apiUrl,
      withCredentials: true
    })
    api.interceptors.response.use(
      function (response) {
        // Set user store
        let user = jsCookie.get('IouUser')
        if (user) {
          store.commit('setState', {
            prop: 'user',
            value: JSON.parse(user)
          })
        } else {
          store.commit('setState', { prop: 'user', value: null })
        }
        return response
      },
      function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (
            error.response.data != null &&
            error.response.data.message != null
          ) {
            error.message = error.response.data.message
          }
          if ([401].indexOf(error.response.status) !== -1) {
            // Auto logout if 401 Unauthorized
            error.message = 'Unauthorized request.'
            if (window.location.hostname == 'localhost') {
              jsCookie.remove('IouUser', { domain: 'localhost' })
            } else {
              jsCookie.remove('IouUser', { domain: '.ioukid.com' })
            }
            store.commit('setState', { prop: 'user', value: null })
            router.replace('/').catch(() => {})
          }
          if ([403].indexOf(error.response.status) !== -1) {
            error.message = 'Unauthorized request.'
          }
          if ([404].indexOf(error.response.status) !== -1) {
            error.message = '404 Not Found'
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          error.message = 'Service unavailable. Check your internet connection.'
        } else {
          // Something happened in setting up the request that triggered an Error
          error.message = 'Unable to send request'
        }
        return Promise.reject(error)
      }
    )
    return api
  },
  get apiNoInterceptors() {
    let apiNoInterceptors = axios.create({
      baseURL: store.state.appConfig.apiUrl,
      withCredentials: true
    })
    apiNoInterceptors.interceptors.response.use(
      function (response) {
        // Set user store
        let user = jsCookie.get('IouUser')
        if (user) {
          store.commit('setState', {
            prop: 'user',
            value: JSON.parse(user)
          })
        } else {
          store.commit('setState', { prop: 'user', value: null })
        }
        return response
      },
      function (error) {
        return Promise.reject(error)
      }
    )
    return apiNoInterceptors
  }
}

export default IouKidApi
