<template>
  <v-app>
    <v-navigation-drawer
      v-if="$store.getters.user"
      v-model="drawer"
      app
      clipped
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <site-logo :width="100" color="black" />
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $store.getters.user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list dense nav>
        <v-list-item :to="'/'">
          <v-list-item-icon>
            <v-icon>mdi-card-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cards</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-for="card in $store.state.cards"
          :key="card.code"
          :to="`/card/${card.code}`"
        >
          <v-list-item-title class="pl-6" v-text="card.codename" />
        </v-list-item>
        <v-list-item :to="'/allowance'">
          <v-list-item-icon>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Allowances</v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/sharing'">
          <v-list-item-icon>
            <v-icon>mdi-share-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sharing</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-checkbox v-model="allDevices" label="Sign out of all devices" />
          <v-btn tile block color="primary" @click="signOut">Sign Out</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon
        v-if="$store.getters.user"
        @click.stop="drawer = !drawer"
      />
      <site-logo
        :width="120"
        color="white"
        @click="$router.push('/').catch(() => {})"
        style="cursor: pointer"
      />
      <v-toolbar-title
        v-if="$store.state.appConfig.environment"
        @click="$router.push('/').catch(() => {})"
        class="headline"
        style="cursor: pointer"
      >
        <v-row no-gutters align="center">
          <span class="ml-2 mt-1 text-subtitle-1"
            >({{ $store.state.appConfig.environment }})</span
          >
        </v-row>
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="!$store.getters.user"
        :loading="profileLoading"
        @click="showSignIn = true"
        tile
        text
        >Sign In
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container v-show="loading" class="text-center mt-10">
        <v-progress-circular :size="120" color="primary" indeterminate />
      </v-container>
      <router-view
        v-show="!loading"
        @loading="setLoading"
        @get-cards="getCards"
        @sign-in="showSignIn = true"
      />
    </v-main>
    <v-footer app>
      <v-col class="text-center pa-0" style="color: #9aa0a5; font-size: 9px">
        &copy; {{ new Date().getFullYear() }} Galaxy Pay, LLC - Version
        {{ $store.state.appVersion }}
      </v-col>
    </v-footer>
    <sign-in-dialog :visible="showSignIn" @close="showSignIn = false" />
    <v-snackbar
      v-model="snackbar.display"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.display = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Separate Snackbar for App Updates -->
    <v-snackbar
      bottom
      right
      :value="$store.state.updateExists"
      :timeout="-1"
      color="info"
      >An update is available.
      <template #action="{ attrs }">
        <v-btn text color="white" v-bind="attrs" @click="refreshApp">
          Update
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import IouKidApi from "@/services/IouKidApi.js";

export default {
  name: "App",
  components: {
    SignInDialog: () => import("@/components/SignInDialog.vue"),
    SiteLogo: () => import("@/components/SiteLogo.vue"),
  },
  data: () => ({
    drawer: false,
    allDevices: false,
    loading: false,
    profileLoading: false,
    showSignIn: false,
    snackbar: {},
  }),
  watch: {
    "$vuetify.theme.dark"(val) {
      localStorage.dark = val;
    },
  },
  async created() {
    await this.loginCallbackCheck();
    this.$event.$on("show-snackbar", (text, color, timeout) => {
      this.showSnackbar(text, color, timeout);
    });
    if (localStorage.dark == "true") this.$vuetify.theme.dark = true;
    // listen for service worker updates
    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });
  },
  methods: {
    async getProfile() {
      this.profileLoading = true;
      await IouKidApi.apiNoInterceptors
        .get("auth/profile")
        .then(async () => {
          await this.getCards();
        })
        .catch(() => {});
      this.profileLoading = false;
    },
    async loginCallbackCheck() {
      this.profileLoading = true;
      let ticket = this.$route.query.ticket;
      if (ticket == null) {
        await this.getProfile();
      } else {
        await IouKidApi.apiNoInterceptors
          .post("auth/redeem", { ticket: ticket })
          .then(async () => {
            await this.getCards();
            this.$router.replace("/").catch(() => {});
            this.showSnackbar("You are signed in!", "success");
          })
          .catch(async () => {
            await this.getProfile();
            if (!this.$store.getters.user) {
              this.showSnackbar("Invalid or expired login link.", "error");
            }
            this.$router.replace("/").catch(() => {});
          });
      }
      this.profileLoading = false;
    },
    showSnackbar(text, color = "info", timeout = 4000) {
      if (color === "error") timeout = 15000;
      this.$set(this.snackbar, "text", text);
      this.$set(this.snackbar, "color", color);
      this.$set(this.snackbar, "timeout", timeout);
      this.$set(this.snackbar, "display", true);
    },
    setLoading(val) {
      this.loading = val;
    },
    updateAvailable(event) {
      this.registration = event.detail;
      this.$store.commit("setState", { prop: "updateExists", value: true });
    },
    refreshApp() {
      this.$store.commit("setState", { prop: "updateExists", value: false });
      if (this.registration && this.registration.waiting) {
        this.loading = true;
        this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    },
    async getCards() {
      if (this.$store.getters.user) {
        await IouKidApi.api
          .get("card")
          .then((response) => {
            this.$store.commit("setState", {
              prop: "cards",
              value: response.data.map((x) => ({
                ...x,
                codename: x.name || x.code,
              })),
            });
          })
          .catch((error) => {
            this.$event.$emit("show-snackbar", error.message, "error");
          });
      }
    },
    async signOut() {
      await IouKidApi.api
        .post(`auth/logoff?allDevices=${this.allDevices}`)
        .then(() => {
          this.$router.replace("/").catch(() => {});
          this.drawer = false;
          this.showSnackbar("Signed out.", "success");
        })
        .catch((error) => {
          this.$event.$emit("show-snackbar", error.message, "error");
        });
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}
.v-data-table--dense tr td {
  font-size: 0.75rem !important;
}
</style>
