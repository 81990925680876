<template>
  <v-container>
    <v-card>
      <v-card-title>
        Sharing
        <v-spacer />
        <v-btn @click="openDialog()" icon>
          <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        Allow trusted partners to manage your cards.
      </v-card-subtitle>
      <v-divider />
      <v-data-table
        :headers="partnerHeaders"
        :items="partners"
        hide-default-footer
        disable-sort
        disable-pagination
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.remove`]="{ item }">
          <v-btn
            fab
            x-small
            text
            color="error"
            @click.stop="removePartner(item)"
            ><v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <!-- partner dialog -->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-form
        ref="partner"
        v-model="valid"
        lazy-validation
        @submit.prevent="addPartner"
      >
        <v-card>
          <v-card-title>Add Partner</v-card-title>
          <v-divider />
          <v-container>
            <v-row justify="center">
              <v-col cols="9">
                <v-text-field
                  v-model="partner.first_name"
                  label="First name"
                  name="fname"
                  autocomplete="given-name"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="partner.last_name"
                  label="Last name"
                  name="lname"
                  autocomplete="family-name"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="partner.email"
                  label="Email"
                  name="email"
                  autocomplete="email"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="showDialog = false"
              tile
              color="secondary"
              :disabled="loading"
              >Cancel
            </v-btn>
            <v-btn
              tile
              color="primary"
              :loading="loading"
              :disabled="!valid"
              type="submit"
              >Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import IouKidApi from "@/services/IouKidApi.js";
import rules from "@/utils/rules.js";

export default {
  name: "SharingView",
  data: () => ({
    partnerHeaders: [
      { text: "First Name", value: "first_name" },
      { text: "Last Name", value: "last_name" },
      { text: "Email", value: "email" },
      { text: "Remove", value: "remove" },
    ],
    partners: [],
    partner: {},
    showDialog: false,
    valid: true,
    loading: false,
    rules,
  }),
  async created() {
    await this.getPartners();
  },
  methods: {
    async getPartners() {
      await IouKidApi.api
        .get("auth/partners")
        .then((response) => {
          this.partners = response.data;
        })
        .catch((error) => {
          this.$event.$emit("show-snackbar", error.message, "error");
        });
    },
    openDialog() {
      this.partner = {};
      requestAnimationFrame(() => {
        this.$refs.partner.reset();
      });
      this.showDialog = true;
    },
    async addPartner() {
      if (this.$refs.partner.validate()) {
        this.loading = true;
        await IouKidApi.apiNoInterceptors
          .post("auth/link", this.partner)
          .then(async () => {
            this.$event.$emit("show-snackbar", "Partner added.", "success");
            await this.getPartners();
          })
          .catch((error) => {
            this.$event.$emit("show-snackbar", error.message, "error");
          });
        this.loading = false;
        this.showDialog = false;
      }
    },
    async removePartner(partner) {
      if (confirm("Are you sure you want to remove this partner?"))
        await IouKidApi.apiNoInterceptors
          .post("auth/unlink", partner)
          .then(async () => {
            this.$event.$emit("show-snackbar", "Partner removed.", "success");
            await this.getPartners();
          })
          .catch((error) => {
            this.$event.$emit("show-snackbar", error.message, "error");
          });
    },
  },
};
</script>
