<template>
  <v-container fluid class="pa-0 text-center">
    <v-container fluid>
      <v-row class="text-h3">
        <v-col
          >Balance:
          {{ usd(card.balance) }}
        </v-col>
      </v-row>
      <v-row class="text-h4">
        <v-col>{{ (is_auth && card.name) || card.code }}</v-col>
      </v-row>
    </v-container>
    <v-container>
      <vue-qrcode v-if="!is_auth" :value="qrcode" />
      <v-row v-else>
        <v-col>
          <v-btn tile color="primary" @click="openDialog">
            Make Adjustment
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>{{ cardTitle }}</v-card-title>
            <v-divider />
            <v-data-table
              dense
              :headers="activityHeaders"
              :items="card.adjustments"
              hide-default-footer
              disable-sort
              disable-pagination
              :mobile-breakpoint="0"
            >
              <template v-slot:[`item.datetime_local`]="{ item }">
                {{ toDTLocal(item.datetime_utc) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ safeToFixed(item.amount, 2) }}
              </template>
              <template v-slot:[`item.resulting_balance`]="{ item }">
                {{ safeToFixed(item.resulting_balance, 2) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- adjustment dialog -->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-form
        ref="adjust"
        v-model="valid"
        lazy-validation
        @submit.prevent="postAdjustment"
      >
        <v-card>
          <v-card-title>
            Make Adjustment
            <v-spacer />
            <v-btn @click="showDialog = false" icon small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-container>
            <v-row justify="center">
              <v-col cols="9">
                <v-textarea
                  label="Memo *"
                  v-model="adjustment.memo"
                  rows="3"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="9">
                <v-select
                  :items="['Add', 'Deduct']"
                  v-model="adjustType"
                  label="Add/Deduct *"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="9">
                <v-text-field
                  label="Amount *"
                  type="number"
                  v-model.number="adjustment.absamount"
                  :rules="[rules.required, rules.decimal]"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="showDialog = false"
              tile
              color="secondary"
              :disabled="processing"
              >Cancel
            </v-btn>
            <v-btn
              tile
              color="primary"
              :loading="processing"
              type="submit"
              :disabled="!valid"
              >Adjust
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import IouKidApi from "@/services/IouKidApi.js";
import VueQrcode from "vue-qrcode";
import rules from "@/utils/rules.js";

export default {
  name: "CardView",
  props: { code: { type: String } },
  components: {
    VueQrcode,
  },
  data: () => ({
    card: {},
    showDialog: false,
    valid: true,
    adjustType: null,
    adjustment: {},
    processing: false,
    rules,
  }),
  computed: {
    is_auth() {
      if (this.$store.getters.user) return true;
      else return false;
    },
    qrcode() {
      return document.location.href.replaceAll("-", "");
    },
    activityHeaders() {
      let headers = [
        { text: "Date/Time", value: "datetime_local", width: 140 },
        { text: "Memo", value: "memo" },
        { text: "Amount", value: "amount", align: "end" },
      ];
      if (this.$vuetify.breakpoint.smAndUp) {
        headers.push({
          text: "Balance",
          value: "resulting_balance",
          align: "end",
        });
      }
      return headers;
    },
    cardTitle() {
      return `${!this.is_auth ? "Recent " : ""} Adjustments`;
    },
  },
  watch: {
    code: {
      immediate: true,
      async handler() {
        this.$emit("loading", true);
        await this.getCard();
        this.$emit("loading", false);
      },
    },
  },
  methods: {
    async getCard() {
      await IouKidApi.api
        .get(`card/${this.code}${this.is_auth ? "/auth" : ""}`)
        .then(async (response) => {
          this.card = response.data;
          if (!this.is_auth) {
            let recent = [];
            if (localStorage.recent) {
              recent = JSON.parse(localStorage.recent);
            }
            if (!recent.includes(this.card.code)) {
              recent.push(this.card.code);
              localStorage.recent = JSON.stringify(recent);
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status == 404) {
            this.$event.$emit("show-snackbar", "Invalid Card Code", "error");
          } else {
            this.$event.$emit("show-snackbar", error.message, "error");
          }
          this.$router.push("/");
          this.$emit("loading", false);
        });
    },
    async postAdjustment() {
      if (this.$refs.adjust.validate()) {
        this.processing = true;
        let amount =
          Math.abs(this.adjustment.absamount) *
          (this.adjustType == "Deduct" ? -1 : 1);
        this.$set(this.adjustment, "code", this.code);
        this.$set(this.adjustment, "amount", amount);
        await IouKidApi.api
          .post("card/adjust", this.adjustment)
          .then(async (response) => {
            this.showDialog = false;
            let message;
            let is_approved;
            switch (response.data.status_code) {
              case 1:
                message = `Approved. Resulting Balance: $${response.data.resulting_balance}`;
                is_approved = true;
                break;
              case 20:
                message = "Declined. Insufficient Funds";
                break;
              default:
                message = "Error. Try Again";
                break;
            }
            this.$event.$emit(
              "show-snackbar",
              message,
              is_approved ? "success" : "warning"
            );
            await this.getCard();
          })
          .catch((error) => {
            this.$event.$emit("show-snackbar", error.message, "error");
          });
        this.processing = false;
      }
    },
    openDialog() {
      requestAnimationFrame(() => {
        this.$refs.adjust.reset();
      });
      this.showDialog = true;
    },
  },
};
</script>
