<template>
  <v-container class="px-1">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="5">
          <table style="width: 100%">
            <tr>
              <td>New Cards</td>
              <td>{{ this.$store.state.numberOfCards }}</td>
              <td class="text-right">{{ intent.new_card_fees }}</td>
            </tr>
            <tr v-if="intent.prorated_days">
              <td>Prorated Fees</td>
              <td>
                {{ intent.prorated_card_count }} Cards
                {{ intent.prorated_days }} Days
              </td>
              <td class="text-right">{{ intent.prorated_fees }}</td>
            </tr>
            <tr class="text-h5">
              <td>Total:</td>
              <td></td>
              <td class="text-right">{{ usd(intent.total) }}</td>
            </tr>
          </table>
          <v-checkbox
            dense
            class="checkbox-small"
            :label="`I authorize Galaxy Pay to automatically charge me
              ${usd(
                this.intent.new_recurring_fee
              )} each month continuously until I cancel.
              I may cancel at any time. No partial refunds.`"
            v-model="agree"
          />
        </v-col>
        <v-col cols="12" sm="7">
          <v-card>
            <v-container>
              <v-row>
                <v-col>
                  <div id="card-element"></div>
                  <div id="card-errors" role="alert"></div>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="pt-1">
                  <v-btn
                    block
                    tile
                    color="primary"
                    @click="pay()"
                    :loading="processing"
                    :disabled="!agree"
                    >Submit Payment
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import IouKidApi from "@/services/IouKidApi.js";
import rules from "@/utils/rules.js";

export default {
  name: "CheckoutView",
  data: () => ({
    intent: {},
    stripe: null,
    card: null,
    processing: false,
    agree: false,
    rules,
  }),
  async created() {
    if (!this.$store.state.numberOfCards) {
      this.$router.push("/");
    }
    await this.getIntent();
  },
  mounted() {
    // eslint-disable-next-line
    this.stripe = Stripe(this.$store.state.appConfig.stripePublishableKey);
    let elements = this.stripe.elements();
    let style = (style = {
      base: {
        color: "#32325d",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    });
    this.card = elements.create("card", { style: style });
    this.card.mount("#card-element");
    this.card.on("change", (event) => {
      document.querySelector("button").disabled = event.empty;
      document.querySelector("#card-errors").textContent = event.error
        ? event.error.message
        : "";
    });
  },
  methods: {
    async pay() {
      let displayError = document.querySelector("#card-errors");
      displayError.textContent = "";
      this.processing = true;
      try {
        await this.stripe
          .confirmCardPayment(this.intent.client_secret, {
            payment_method: { card: this.card },
            setup_future_usage: "off_session",
          })
          .then(async (result) => {
            if (result.error) {
              displayError.textContent = result.error.message;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                await IouKidApi.api
                  .post("payment/complete", {
                    intent_id: result.paymentIntent.id,
                  })
                  .then(() => {
                    this.$emit("get-cards");
                  })
                  .catch((error) => {
                    this.$event.$emit("show-snackbar", error.message, "error");
                  });
                this.$event.$emit(
                  "show-snackbar",
                  "Payment Received. Thank You!",
                  "success"
                );
                this.$router.push("/sign-in").catch(() => {});
              }
            }
          });
      } catch {
        let errorMessage = "Error processing your request";
        this.$event.$emit("show-snackbar", errorMessage, "error");
        displayError.textContent = errorMessage;
      }
      this.processing = false;
    },
    async getIntent() {
      this.$emit("loading", true);
      await IouKidApi.api
        .post("card/add", {
          number_of_cards: this.$store.state.numberOfCards,
        })
        .then((response) => {
          this.intent = response.data;
        })
        .catch((error) => {
          this.$event.$emit("show-snackbar", error.message, "error");
        });
      this.$emit("loading", false);
    },
  },
};
</script>
<style>
.checkbox-small .v-label {
  font-size: 11px;
}
</style>
