import Vue from 'vue'
import Vuex from 'vuex'
import jsCookie from 'js-cookie'
import createMultiTabState from 'vuex-multi-tab-state'

Vue.use(Vuex)

const store = new Vuex.Store({
  state() {
    return {
      appVersion: process.env.VUE_APP_VERSION || '0',
      appTitle: 'IOU Kid!'
    }
  },
  getters: {
    user: state => {
      // The user store will be empty on initial load
      // Check to see if the cookie exists
      if (!state.user) {
        let user = jsCookie.get('IouUser')
        if (user) {
          return JSON.parse(user)
        }
      }
      return state.user
    }
  },
  mutations: {
    setConfig(state, appConfig) {
      Vue.set(state, 'appConfig', appConfig)
      if (appConfig.environment)
        Vue.set(
          state,
          'appTitle',
          state.appTitle + ' (' + appConfig.environment + ')'
        )
    },
    setState(state, obj) {
      Vue.set(state, obj.prop, obj.value)
    }
  },
  plugins: [
    createMultiTabState({
      statesPaths: ['user', 'cards']
    })
  ]
})

export default store
